<template>
  <div>
    <VueSlickCarousel v-bind="settings"
      class="slider-digital-agency slider-activation rn-slick-dot dot-light mb--0 color-white">
      <div class="slide slide-style-2 fullscreen d-flex align-center bg_image" data-black-overlay="2"
        v-for="(slider, i) in sliderContent" :key="i" :style="{ backgroundImage: 'url(' + slider.src + ')' }">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner text-center">
                <h1 class="heading-title">{{ slider.title }}</h1>
                <p class="description">
                  {{ slider.desc }}
                </p>
                <div class="slide-btn">
                  <a href="https://photo.drivezone.com.ua/" class="rn-button-style--2 btn-primary-color">{{
                    $t('message.18') }}</a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliderContent: [],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20,
      },
    };
  },
  created() {
    this.initializeSliderContent();
  },
  watch: {
    '$i18n.locale'() {
      this.initializeSliderContent();
    }
  },
  methods: {
    initializeSliderContent() {
      this.sliderContent = [
        {
          src: require("../../assets/images/Bukovel.jpg"),
          title: this.$t('message.19'),
          desc: this.$t('message.20'),
        },
        {
          src: require("../../assets/images/Dnipro.jpg"),
          title: this.$t('message.21'),
          desc: this.$t('message.22'),
        },
      ];
    }
  }
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
</style>