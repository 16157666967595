<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabContent" :key="item.id">
          <div class="single-tab-content">
            <p>
              {{ item.desc }}
            </p>
            <div class="list0style-inner mt--30">
              <h4>{{ $t('message.4') }}</h4>
              <ul class="list-style--1">
                <li class="d-flex" v-for="(list, i) in listOne" :key="i">
                  <i v-html="iconSvg(list.icon)"></i>
                  {{ list.desc }}
                </li>
              </ul>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
import feather from "feather-icons";

export default {
  data() {
    return {
      tab: null,
      items: [],
      tabContent: [],
      listOne: [],
    };
  },
  created() {
    this.initializeData();
  },
  watch: {
    '$i18n.locale'() {
      this.initializeData();
    }
  },
  methods: {
    initializeData() {
      this.items = [
        {
          id: 1,
          name: this.$t('message.5'),
        },
        {
          id: 2,
          name: this.$t('message.6'),
        },
        {
          id: 3,
          name: this.$t('message.7'),
        },
      ];

      this.tabContent = [
        {
          id: 1,
          desc: this.$t('message.8'),
          content: [{}],
        },
        {
          id: 2,
          desc: this.$t('message.9'),
          content: [{}],
        },
        {
          id: 3,
          desc: this.$t('message.10'),
          content: [{}],
        },
      ];

      this.listOne = [
        {
          icon: "check",
          desc: this.$t('message.11'),
        },
        {
          icon: "check",
          desc: this.$t('message.12'),
        },
        {
          icon: "check",
          desc: this.$t('message.13'),
        },
        {
          icon: "check",
          desc: this.$t('message.14'),
        },
      ];
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>